import React, { useEffect, useRef } from "react";
import { Helmet } from "react-helmet";

const ShippingPolicy = () => {
  const sectionRef = useRef(null);
  const currentPageUrl = window.location.href;
  useEffect(() => {
    if (window.location.hash === "#shipping&refund-policy") {
      sectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);
  return (
    <div
      className="container"
      style={{ marginTop: 65, textAlign: "justify" }}
      ref={sectionRef}
    >
      <Helmet>
        <title>Shipping & Refund Policy</title>
        <meta property="og:title" content={"Shipping & Refund Policy"} />
        <meta property="og:url" content={currentPageUrl} />
        <meta property="og:site_name" content="Zaya Dryfruits" />
      </Helmet>
      <br />
      <h4 className="textColor text-center">Shipping & Refund Policy</h4>
      <p className="textColor">Last updated June 24, 2021</p>
      <hr />
      <p>
        Thank you for your purchase. We hope you are happy with your purchase.
        However, if you are not completely satisfied with your purchase for any
        reason, you may return it to us for a full refund, store credit, or an
        exchange . Please see below for more information on our return policy.
      </p>

      <h4 className="textColor">SHIPPING</h4>
      <p>
        Accepted order will be delivered in time of 1 hours to 48 hours (depends
        on the distance from our stores) Free shipping to Delhi, Gurugram and
        Noida area. Except these locations, shipping charges will be applicable
        as per actual. All Deliveries will be processed between from 08:00 Am to
        9:00 PM on all days .
      </p>
      <h4 className="textColor">RETURNS</h4>
      <p>
        All returns must be postmarked within seven (7) days of the purchase
        date. All returned items must be in new and unused condition, with all
        original tags and labels attached.
      </p>
      <h4 className="textColor">RETURN PROCESS</h4>
      <p>
        To return an item, please email customer service at
        info@zayadryfruits.com to obtain a Return Merchandise Authorization
        (RMA) number. After receiving a RMA number, place the item securely in
        its original packaging and include your proof of purchase, and mail your
        return to the following address:
      </p>
      <li>
        26, Central Market, Lajpat Nagar II, Lajpat Nagar, New Delhi Delhi
        110024 Attn: Returns RMA #
      </li>
      <li>
        26, Central Market, Lajpat Nagar II, Lajpat Nagar New Delhi, Delhi
        110024 India
      </li>
      <br />
      <p>Return shipping charges will be paid or reimbursed by us.</p>

      <h4 className="textColor">REFUNDS</h4>
      <p>
        After receiving your return and inspecting the condition of your item,
        we will process your return or exchange . Please allow at least seven
        (7) days from the receipt of your item to process your return or
        exchange . Refunds may take 1-2 billing cycles to appear on your credit
        card statement, depending on your credit card company. We will notify
        you by email when your return has been processed.
      </p>
      <h4 className="textColor">EXCEPTIONS</h4>
      <p>
        For defective or damaged products, please contact us at the contact
        details below to arrange a refund or exchange.
      </p>
      <p className="textColor">Please Note</p>
      <li> Sale items are FINAL SALE and cannot be returned.</li>
      <br />
      <h4 className="textColor">QUESTIONSs</h4>
      <p>
        If you have any questions concerning our return policy, please contact
        us at: +(91) 99583 44409 info@zayadryfruits.com
      </p>
      <h4 className="textColor">PAYMENT TERMS</h4>
      <p>
        If Order failed and payment deducted from your account, it will be
        automatically refunded to your account. Payment for refund requested
        will be processed in 4-5 working days after recieving returned product
      </p>
    </div>
  );
};

export default ShippingPolicy;
