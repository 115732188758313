import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { URL } from "../url/api";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import ActivityIndicator from "../component/ActivityIndicator";

const Contact = () => {
  const sectionRef = useRef(null);
  const currentPageUrl = window.location.href;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [comment, setComment] = useState("");
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (window.location.hash === "#contact-us") {
      sectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);
  useEffect(() => {
    checkLogin();
  }, []);

  const checkLogin = async () => {
    try {
      const session = await Cookies.get("user_session");
      if (session !== undefined && session !== null) {
        const token = JSON.parse(session);
        setToken(token);
      } else {
        console.log("isLoggedIn::::::", session);
      }
    } catch (error) {
      // There was an error on the native side
    }
  };

  const showToast = (title) => {
    toast.success(title, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const showToastErr = (title) => {
    toast.error(title, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  const contact = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer${token}`);

    var formdata = new FormData();
    formdata.append("first_name", name);
    formdata.append("email", email);
    formdata.append("mobile_no", phone);
    formdata.append("your_subject", comment);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(`${URL}/contactUs`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const resultResponse = JSON.parse(result);
        if (resultResponse.error === false) {
          const changeData = resultResponse.msg.replace(/[\{\}\[\]]/g, "");
          showToast(changeData);
          setName("");
          setPhone("");
          setEmail("");
          setComment("");
        } else {
          const changeData = resultResponse.msg.replace(/[\{\}\[\]]/g, "");
          showToastErr(changeData);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  if (loading) {
    return <ActivityIndicator />;
  }
  return (
    <div
      className="container"
      style={{ marginTop: 65, textAlign: "justify" }}
      ref={sectionRef}
    >
      <Helmet>
        <title>Contact Us</title>
        <meta property="og:title" content={"Contact Us"} />
        <meta property="og:url" content={currentPageUrl} />
        <meta property="og:site_name" content="Zaya Dryfruits" />
      </Helmet>
      <br />
      <h4 className="textColor text-center"> Contact Us</h4>
      <hr />
      <div className="row">
        <div className="col-12 mb-2">
          <input
            type="text"
            className="form-control"
            placeholder="First name"
            aria-label="First name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="col-6">
          <input
            type="text"
            className="form-control"
            placeholder=" Email"
            aria-label="Last name"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="col-6">
          <input
            type="number"
            className="form-control"
            placeholder="Phone Number"
            aria-label="First name"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </div>
        <div className="col-12 mt-2 mb-2">
          <input
            type="text"
            className="form-control"
            placeholder=" Your Subject"
            aria-label="Last name"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </div>
      </div>
      <button
        type="submit"
        className="btn btn-primary "
        style={{
          width: "40%",
          marginBottom: 20,
          marginTop: 20,
          marginLeft: "30%",
          marginRight: "30%",
        }}
        onClick={() => {
          contact();
        }}
      >
        Submit
      </button>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3504.0241110759885!2d77.2387040749674!3d28.56903868696207!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce3ad454e2841%3A0xd1da9f0ebcb43317!2s26%2C%20Central%20Market%2C%20Lajpat%20Nagar%20II%2C%20Lajpat%20Nagar%2C%20New%20Delhi%2C%20Delhi%20110024!5e0!3m2!1sen!2sin!4v1686567116911!5m2!1sen!2sin"
        width="100%"
        height="400"
        style={{ marginRight: "0%", marginLeft: "0%" }}
        allowFullScreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
};

export default Contact;
